'use client'
import { useState } from "react";
import { httpBatchLink } from "@trpc/client";
import { QueryClientProvider } from "@tanstack/react-query";
import { getQueryClient, getTrpcApiUrl, trpc } from "../infrastructure/trpc/client";
import { transformer } from "../application/trpc/utils";

export function TRPCProvider(
    props: Readonly<{
      children: React.ReactNode;
    }>,
  ) {
  
    // NOTE: Avoid useState when initializing the query client if you don't
    //       have a suspense boundary between this and the code that may
    //       suspend because React will throw away the client on the initial
    //       render if it suspends and there is no boundary
  
    const queryClient = getQueryClient();
    
    const [trpcClient] = useState(() =>
      trpc.createClient({
        links: [
          httpBatchLink({
            transformer: transformer,
            url: getTrpcApiUrl()
          }),
        ],
      }),
    );
  
    return (
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          {props.children}
        </QueryClientProvider>
      </trpc.Provider>
    );
  }