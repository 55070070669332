import { EJSON } from 'bson';
import superjson from 'superjson';

// superjson.registerCustom<ObjectId, string>(
//     {
//       isApplicable: (v): v is ObjectId => v instanceof ObjectId,
//       serialize: v => v.toString(),
//       deserialize: v => new ObjectId(v),
//     },
//     'objectid'
// );

export const transformer = {
    input: {
        // Used on client side mutation
        serialize: (object: any) => {
            // console.log('input serialize', object);
            return object;
        },

        // Used on server side mutation
        deserialize: (object: any) => {
            // console.log('input deserialize', object);
            return object;
        },
    },
    output: {
        // Used on server side query
        serialize: (object: any) => {
            // console.log('output serialize', object);
            return object;
        },
        // Used on client side query
        deserialize: (object: any) => {
            // console.log('output deserialize', object);
            return object;
        },
    },
};